<template>
  <div @click.stop>
    <nav
      :class="{
        '!w-60 is-open': isNavigationOpen,
      }"
      class="sticky ml-16 w-[4.5rem] overflow-hidden transition-all duration-[150ms] ease-linear bg-white tpf-menu shadow-home-teasers"
    >
      <button class="flex items-center outline-none" @click="toggleSidebar">
        <div class="flex justify-center">
          <SvgIcon
            v-show="isNavigationOpen"
            name="times-light"
            class="self-end"
            :title="$t('navigation.open')"
          />
          <SvgIcon
            v-show="!isNavigationOpen"
            name="bars-light"
            class="self-end"
            aria-hidden="true"
          />
          <span v-show="isNavigationOpen">
            {{ $t("navigation.close") }}
          </span>
        </div>
      </button>

      <slot :is-nav-open="isNavigationOpen" />
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onDeactivated } from "vue";

const isNavigationOpen = ref(false);

onMounted(() => {
  window.addEventListener("click", closeSidebar);
});

onDeactivated(() => {
  window.removeEventListener("click", closeSidebar);
});

const toggleSidebar = () => {
  isNavigationOpen.value = !isNavigationOpen.value;
};

const closeSidebar = () => {
  isNavigationOpen.value = false;
};
</script>
