<template>
  <nav class="fixed bottom-0 z-10 w-full bg-white lg:hidden mobile-menu">
    <ul class="flex justify-between list-none">
      <li>
        <RouterLink :to="{ name: 'home', params: routeParams }">
          <div class="icon">
            <SvgIcon name="home" class="svg-icon--lg" />
          </div>
          <span>{{ $t("navigation.home") }}</span>
        </RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'counseling', params: routeParams }">
          <div class="icon relative">
            <UnreadDialogIndicator class="!right-0" />
            <SvgIcon name="messages" class="svg-icon--lg" />
          </div>
          <span>{{ $t("navigation.counseling") }}</span>
        </RouterLink>
      </li>
      <li>
        <RouterLink :to="{ name: 'goals', params: routeParams }">
          <div class="icon">
            <SvgIcon name="goal" class="svg-icon--lg" />
          </div>
          <span>{{ $t("navigation.goal") }}</span>
        </RouterLink>
      </li>
    </ul>
  </nav>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import UnreadDialogIndicator from "@/components/UnreadDialogIndicator.vue";

const { locale } = useI18n();

const routeParams = computed(() => ({ language: locale.value }));
</script>

<style lang="postcss" scoped>
.mobile-menu {
  box-shadow: 0px -2px 6px 0px rgba(0, 0, 0, 0.06);

  a {
    @apply flex flex-col items-center;
    @apply pt-3 pb-4 w-28;
    @apply no-underline;

    .icon {
      @apply p-1;
    }

    span {
      @apply mt-2 text-xs;
    }

    &.router-link-active {
      @apply text-primary-700;

      .icon {
        @apply rounded-full bg-secondary-500;

        svg {
          @apply text-primary-700;
        }
      }
    }
  }
}
</style>
